import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { useDataStore } from "../store";

const Input = styled.input`
  border: ${props =>
    props.error ? "1px solid #ff4d4f" : "1px solid rgb(188 194 210)"};
  width: 100%;
  font-size: 18px;
  padding: 12px 16px;
  border-radius: 8px;
  &::placeholder {
    color: rgb(195 197 203);
  }
`;

const InputTitle = styled(Typography)`
  &&& {
    font-size: 14px;
    color: rgb(135 139 150);
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin-top: 8px;
  color: #ff4d4f;
  font-size: 14px;
`;

function TextInput({
  cache,
  onChange,
  type,
  keyName,
  title,
  placeholder,
  error,
  value,
  disabled,
}) {
  const [data, setData] = useDataStore();
  useEffect(() => {
    if (cache) {
      if (
        (!!localStorage.getItem(keyName) &&
          parseInt(localStorage.getItem("lastSave")) + 900000 >
            new Date().getTime()) === true
      ) {
        setData(localStorage.getItem(keyName));
        if (onChange) onChange(localStorage.getItem(keyName));
      }
    }
  }, []);
  useEffect(() => {
    setData({ [keyName]: value });
  }, [value])
  return (
    <div>
      {title && <InputTitle>{title}</InputTitle>}
      <Input
        type={type}
        value={data[keyName] || ""}
        onChange={event => {
          setData({ [keyName]: event.target.value });
          if (onChange) onChange(event.target.value);
          if (cache) {
            localStorage.setItem(keyName, event.target.value);
            localStorage.setItem("lastSave", new Date().getTime());
          }
        }}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

TextInput.propTypes = {
  cache: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextInput;
