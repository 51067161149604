import React from "react";
import styled from "styled-components";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import TopBar from "../components/TopBar";
import ErrorImage from "../assets/images/error_maintenance.png";

const Container = styled.div`
  margin: 16px;
  margin-top: 66px;
`;

const Button = styled.button`
  color: white;
  padding: 10px 25px;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: #ff8300;
`;

const ImageContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeadText = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #0e162c;
`;

const Text = styled.p`
  text-align: center;
  font-size: 16px;
  color: #999999;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterImage = styled.img`
  width: 120px;
  height: 120px;
`;

function ErrorTopup() {
  const onBack = () => {
    window.location.replace(
      "https://tmn" +
        (process.env.REACT_APP_ENV === "production" ? "" : "-dev") +
        ".app.link/home"
    );
  };

  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          <CenterImage alt="error_image" src={ErrorImage} />
        </ImageContainer>
        <HeadText>ระบบกำลังปรับปรุง</HeadText>
        <Text>ขออภัยในความไม่สะดวก กรุณาทำรายการใหม่ภายหลัง</Text>
        <br />

        <ButtonContainer>
          <Button onClick={onBack}>กลับสู่หน้าหลัก</Button>
        </ButtonContainer>
      </Container>
      <Header>
        <TopBar
          close={() => {
    window.location.replace(
      "https://tmn" +
        (process.env.REACT_APP_ENV === "production" ? "" : "-dev") +
        ".app.link/home"
    );
          }}
        />
      </Header>
    </Wrapper>
  );
}

export default ErrorTopup;
