import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import TopBar from "../components/TopBar";
import TopBarTitle from "../components/TopBarTitle";
import Body from "../components/Body";
import SubmitButton from "../components/SubmitButton";
import { useHistory } from "react-router-dom";
import McashImage from "../assets/images/mgen1.png";
import styled from "styled-components";
import TextInput from "../components/TextInput";
import { useAuthStore, useDataStore } from "../store";
import Loading from "../components/Loading";
import API from "../api";
import Modal from "@material-ui/core/Modal";

const Fullwidth = styled.div`
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
`;

const TextDeletingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ModalContainer = styled.div`
  overflow: auto;
  outline: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 90%;
  max-height: 80%;
  background-color: white;
  border-radius: 8px;
`;

const ModalContainerContent = styled.div`
  padding: 16px;
`;

const DeleteButton = styled.button`
  color: #1b7df3;
  display: block;
  margin: auto;
  outline: none;
  border: none;
  background: none;
  color: #1b7df3;
  font-size: 16px;
  padding: 3px 40px;
`;

const TopImage = styled.img`
  width: 100%;
`;

const TopImageContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: auto;
  display: block;
  margin-top: 48px;
  padding: 0px 24px;
  position: relative;
`;

const CardName = styled.p`
  margin: 0px;
  color: white;
  font-size: 12px;
`;

const CardNumber = styled.p`
  margin: 0px;
  margin-top: 3px;
  color: white;
  font-size: 11px;
`;

const CardNameContainer = styled.div`
  width: 100%;
`;

const InfoCardContainer = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: flex-end;
`;

const PaddingContent = styled.div`
  padding: 16px;
`;

function AddCard() {
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const history = useHistory();
  const [data, setData] = useDataStore();
  const [auth] = useAuthStore();
  const { token } = auth;
  const { cards = [], isEditCard = false, editCardData = {} } = data;
  const addCard = async () => {
    if (cardName.length === 0) return setIsNameError(true);
    if (cardNumber.length !== 16) return setIsError(true);
    setIsLoading(true);
    if (isEditCard) {
      const editCardResponse = await API.putWithToken(token, "/manage_card", {
        card_name: cardName,
        card_number: cardNumber
      });
      const editCardData = editCardResponse.data.data;
      const parsedData = JSON.parse(editCardData);
      const cardIds = cards.map(c => c.id);
      const oldIndex = cardIds.indexOf(parsedData.id);
      const newCards = [];
      cards.forEach((c, index) => {
        if (index !== oldIndex) {
          newCards.push(c);
        } else {
          newCards.push(parsedData);
        }
      });
      setData({
        cards: newCards,
        isEditCard: false,
        setCardToId: parsedData.id,
        editCardData: {}
      });
    } else {
      const addCardResponse = await API.postWithToken(token, "/manage_card", {
        card_name: cardName,
        card_number: cardNumber
      });
      const addCardData = addCardResponse.data.data;
      const parsedData = JSON.parse(addCardData);
      const newCards = [...cards, parsedData];
      setData({
        cards: newCards,
        isEditCard: false,
        setCardToId: parsedData.id,
        editCardData: {}
      });
    }
    history.push("/home");
    setIsLoading(false);
  };
  useEffect(() => {
    if (isEditCard === true) {
      if (editCardData.name) setCardName(editCardData.name);
      if (editCardData.card_number) setCardNumber(editCardData.card_number);
    }
  }, [isEditCard]);
  const popupIsDeleting = () => {
    setIsDeleting(true);
  };
  const closePopupDeleting = () => {
    setIsDeleting(false);
  };
  const removeCard = async () => {
    setIsLoading(true);
    const deleteResponse = await API.deleteWithToken(token, "/manage_card", {
      card_number: editCardData.card_number
    });
    const deleteResponseData = deleteResponse.data || {};
    if (deleteResponseData.code === 0) {
      let idToGo = undefined;
      cards.forEach((c, index) => {
        if (c.id === editCardData.id && index !== 0) {
          idToGo = cards[index].id;
        }
      });
      const newCards = cards.filter(c => c.id !== editCardData.id);
      setData({
        cards: newCards,
        isEditCard: false,
        setCardToId: idToGo,
        editCardData: {}
      });
    }
    setIsLoading(false);
    setIsDeleting(false);
    history.push("/home");
  };
  return (
    <Wrapper>
      <Body>
        <TopImageContainer>
          <TopImage src={McashImage} alt="cards" />
          <InfoCardContainer>
            <CardNameContainer>
              <CardName>{cardName}</CardName>
              <CardNumber>{cardNumber}</CardNumber>
            </CardNameContainer>
          </InfoCardContainer>
        </TopImageContainer>
        <PaddingContent>
          <TextInput
            value={cardNumber}
            keyName="newCard"
            title="หมายเลขบัตร M GENERATION"
            disabled={isEditCard}
            onChange={text => {
              setCardNumber(text);
              setIsError(false);
            }}
            type="number"
            error={isError && "รหัสบัตร M GENERATION ไม่ถูกต้อง"}
          />
          <TextInput
            value={cardName}
            keyName="newCardName"
            title="ตั้งชื่อบัตร"
            onChange={text => {
              setCardName(text);
              setIsNameError(false);
            }}
            type="text"
            error={isNameError && "กรุณาระบุชื่อบัตร"}
          />
        </PaddingContent>
        <SubmitButton onClick={addCard}>
          <TopBarTitle>{isEditCard ? "บันทึก" : "เพิ่มบัตร"}</TopBarTitle>
        </SubmitButton>
        {isEditCard && (
          <DeleteButton onClick={popupIsDeleting}>ลบบัตร</DeleteButton>
        )}
        <Modal styles={{ outline: 0 }} open={isLoading}>
          <Loading />
        </Modal>
        <Modal styles={{ outline: 0 }} open={isDeleting}>
          <ModalContainer>
            <ModalContainerContent>
              <TextDeletingContainer>
                <TopBarTitle>ลบบัตรใบนี้</TopBarTitle>
                <br />
                <span>บัตรใบนี้จะถูกลบออกจากระบบ</span>
                <br />
              </TextDeletingContainer>
              <Flex>
                <Fullwidth>
                  <SubmitButton clear={true} onClick={closePopupDeleting}>
                    <TopBarTitle>ยกเลิก</TopBarTitle>
                  </SubmitButton>
                </Fullwidth>
                <Fullwidth>
                  <SubmitButton onClick={removeCard}>
                    <TopBarTitle>ยืนยัน</TopBarTitle>
                  </SubmitButton>
                </Fullwidth>
              </Flex>
            </ModalContainerContent>
          </ModalContainer>
        </Modal>
      </Body>
      <Header>
        <TopBar
          back={async () => {
            await setData({
              isEditCard: false,
              editCardData: {},
              setCardToId: editCardData.id
            });
            history.push("/home");
          }}
        >
          <TopBarTitle fontSize={14}>
            {isEditCard ? "แก้ไขบัตร M GENERATION" : "เพิ่มบัตร M GENERATION"}
          </TopBarTitle>
        </TopBar>
      </Header>
    </Wrapper>
  );
}

export default AddCard;
