import axios from "axios";
const API_PATH = process.env.REACT_APP_API_ENDPOINT;

const pathConverter = path => {
  return path[0] === "/" ? path : "/" + path;
};

class API {
  static get = async path => {
    return axios.get(API_PATH + pathConverter(path));
  };

  static post = async (path, body = {}) => {
    return axios.post(API_PATH + pathConverter(path), body);
  };

  static put = async (path, body = {}) => {
    return axios.put(API_PATH + pathConverter(path), body);
  };

  static delete = async (path, body = {}) => {
    return axios.delete(API_PATH + pathConverter(path), body);
  };

  static getWithToken = async (token, path) => {
    return axios.get(API_PATH + pathConverter(path), {
      headers: {
        Authorization: token
      }
    });
  };

  static postWithToken = async (token, path, body = {}) => {
    return axios.post(API_PATH + pathConverter(path), body, {
      headers: {
        Authorization: token
      }
    });
  };

  static putWithToken = async (token, path, body = {}) => {
    return axios.put(API_PATH + pathConverter(path), body, {
      headers: {
        Authorization: token
      }
    });
  };

  static deleteWithToken = async (token, path, body = {}) => {
    return axios.delete(API_PATH + pathConverter(path), {
      data: body,
      headers: {
        Authorization: token
      }
    });
  };
}

export default API;
