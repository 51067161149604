import React, { useState, useEffect } from "react";
import Wrapper from "../components/Wrapper";
import TopBar from "../components/TopBar";
import TopBarTitle from "../components/TopBarTitle";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Body from "../components/Body";
import SubmitButton from "../components/SubmitButton";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAuthStore, useDataStore } from "../store";
import Loading from "../components/Loading";
import API from "../api";
import Modal from "@material-ui/core/Modal";

import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import NewCard from "../assets/images/newcard.png";
import MGen1 from "../assets/images/mgen1.png";
import MGen2 from "../assets/images/mgen2.png";
import MGen3 from "../assets/images/mgen3.png";
import MGen4 from "../assets/images/mgen4.png";

const NumberContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const NumberItem = styled.p`
  font-size: 14px;
  margin-right: 8px;
  margin: 0px;
`;

const NumberText = styled.p`
  font-size: 14px;
  margin: 0px;
`;

const EditCardButton = styled.button`
  background: none;
  outline: none;
  border: 1px solid white;
  padding: 5px 22px;
  border-radius: 15px;
  height: 25px;
  color: white;
  font-size: 10px;
`;

const CardName = styled.p`
  margin: 0px;
  color: white;
  font-size: 11px;
`;

const CardNumber = styled.p`
  margin: 0px;
  margin-top: 3px;
  color: white;
  font-size: 10px;
`;

const CardNameContainer = styled.div`
  width: 100%;
`;

const InfoCardContainer = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: flex-end;
`;

const ImageCardContainer = styled.div`
  width: 300px;
  position: relative;
`;

const CardImage = styled.img``;

const CarouselContainer = styled.div`
  & > ul > li > button {
    background-color: unset;
    transition: all 0.05s ease-in-out;
    border-radius: 20px;
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  & > ul > li > button::before {
    transition: all 0.05s ease-in-out;
    border-radius: 20px !important;
    height: 10px !important;
    width: 10px !important;
  }
  & > ul > li > button.BrainhubCarousel__dot--selected::before {
    width: 20px !important;
    background: #1b7df3 !important;
  }
  & > ul > li > button:hover::before {
    background: #bbbbbb;
  }
  & > div > div > div > ul > li > div > img {
    width: 300px;
  }
`;

const PaddingContainer = styled.div`
  padding: 16px;
  max-width: 400px;
  margin: auto;
`;

const TextTitle = styled.p`
  font-size: 16px;
  color: rgb(14 22 44);
  margin: 0px;
  font-weight: bold;
`;

const Divider = styled.div`
  height: 14px;
  display: block;
`;

const SmallDivider = styled.div`
  height: 6px;
  display: block;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PriceButton = styled.button`
  font-size: 16px;
  outline: none;
  width: 100%;
  margin-right: ${props => (props.left ? "8px" : "0px")};
  margin-left: ${props => (props.right ? "8px" : "0px")};
  border: ${props =>
    props.disabled
      ? "#AAAAAA"
      : props.active
      ? "2px solid #ff8300"
      : "2px solid #DFDFDF"};
  background-color: ${props =>
    props.disabled ? "#EEEEEE" : props.active ? "#ff8300" : "white"};
  color: ${props =>
    props.disabled ? "#AAAAAA" : props.active ? "white" : "rgb(14 22 44)"};
  padding: 8px;
  border-radius: 6px;
`;

function AddCardComponent() {
  const history = useHistory();
  return (
    <>
      <ImageCardContainer
        onClick={() => {
          history.push("/add-card");
        }}
      >
        <CardImage key={"newcard"} src={NewCard} alt="card" />
      </ImageCardContainer>
    </>
  );
}

function Home() {
  const [auth] = useAuthStore();
  const [data, setData] = useDataStore();
  const { token } = auth;
  const {
    isPackageLoadingDone,
    isCardLoadingDone,
    cards = [],
    packages,
    setCardToId
  } = data;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSelectedPackage, setCurrentSelectedPackage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const onSlideChange = value => {
    setCurrentSlide(value);
  };
  const allCards = [...cards, undefined];
  const slides = allCards.map(data => {
    if (data === undefined) return <AddCardComponent />;
    let image = MGen1;
    switch (data.type) {
      case 0:
        image = MGen1;
        break;
      case 1:
        image = MGen2;
        break;
      case 2:
        image = MGen3;
        break;
      case 3:
        image = MGen4;
        break;
      default:
        image = MGen1;
        break;
    }
    const editCard = () => {
      setData({ isEditCard: true, editCardData: data });
      history.push("/add-card");
    };
    return (
      <>
        <ImageCardContainer>
          <CardImage key={data.card_number} src={image} alt="card" />
          <InfoCardContainer>
            <CardNameContainer>
              <CardName>{data.name}</CardName>
              <CardNumber>{data.card_number}</CardNumber>
            </CardNameContainer>
            <EditCardButton onClick={editCard}>แก้ไข</EditCardButton>
          </InfoCardContainer>
        </ImageCardContainer>
      </>
    );
  });

  const currentCard = allCards[currentSlide] || {};

  const addCard = () => {
    history.push("/add-card");
  };

  useEffect(() => {
    const targetCard = cards.map(c => (c || {}).id).indexOf(setCardToId);
    if (targetCard > -1) {
      setCurrentSlide(targetCard);
    }
  }, [setCardToId]);
  useEffect(async () => {
    if (!!isPackageLoadingDone !== true) {
      const packageResponse = await API.getWithToken(token, "/get_packages");
      const {
        data: { data }
      } = packageResponse;
      const allPackages = [];
      let semiPackage = [];
      data.forEach((p, i) => {
        if (i % 2 === 0) {
          semiPackage.push(p);
        } else {
          semiPackage.push(p);
          semiPackage.id = new Date().getTime();
          allPackages.push(semiPackage);
          semiPackage = [];
        }
      });
      setData({
        isPackageLoadingDone: true,
        packages: allPackages
      });
    }
    if (!!isCardLoadingDone !== true) {
      const cardResponse = await API.getWithToken(
        token,
        "/manage_card?action=get"
      );
      const {
        data: { data }
      } = cardResponse;
      if (data.length === 0) {
        history.push("/add-card");
      }
      setData({
        isCardLoadingDone: true,
        cards: data
      });
      console.log(cards);
    }
  }, [token]);

  const topUp = async () => {
    if (
      !!currentSelectedPackage &&
      currentSelectedPackage.id &&
      !!currentCard
    ) {
      setIsLoading(true);
      // const verifyResponse = await API.postWithToken(token, "/verify_card", {
      //   card_number: currentCard.card_number,
      //   selected_package: currentSelectedPackage.id
      // });
      // const verifyData = verifyResponse.data;
      // if (verifyData.code !== 0) {
      //   setData({
      //     editCardData: currentCard,
      //     errorCode: verifyData.code,
      //   });
      //   history.push("/error-topup");
      // } else {
      // const authStateMD5 = btoa(
      //   unescape(encodeURIComponent(JSON.stringify(auth)))
      // );
      const orderResponse = await API.postWithToken(token, "/order", {
        card_number: currentCard.card_number,
        package_id: currentSelectedPackage.id,
        //order_id: verifyData.order_id,
        return_address: process.env.REACT_APP_API_ENDPOINT + "/return?"
        //window.location.origin + "/success?" // s=trure&a=" + authStateMD5
      });
      const orderData = orderResponse.data;
      if (orderData.status && orderData.status.code === "0") {
        const checkout_url = orderData.data.checkout_url;
        window.location.replace(checkout_url);
      } else {
        setData({
          editCardData: currentCard,
          errorCode: orderData.code
        });
        history.push("/error-topup");
      }
      //}
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <Body>
        {!!isPackageLoadingDone !== true || !!isCardLoadingDone !== true ? (
          <Loading />
        ) : (
          <>
            <Divider />
            <CarouselContainer>
              <Carousel
                animationSpeed={50}
                value={currentSlide}
                slides={slides}
                onChange={onSlideChange}
              />
              <Dots
                value={currentSlide}
                onChange={onSlideChange}
                number={allCards.length}
              />
            </CarouselContainer>
            {currentSlide + 1 !== allCards.length && (
              <PaddingContainer>
                <Divider />
                <SmallDivider />
                <TextTitle>เลือกจำนวนเงิน</TextTitle>
                <SmallDivider />
                <div>
                  <SmallDivider />
                  {packages.map((currentPackages, groupIndex) => {
                    return (
                      <div key={currentPackages[0].id}>
                        <FlexContainer>
                          {currentPackages.map((thisPackage, index) => {
                            return (
                              <PriceButton
                                key={thisPackage.id}
                                left={index % 2 === 0}
                                right={index % 2 !== 1}
                                active={
                                  currentSelectedPackage.total_premium ===
                                  thisPackage.total_premium
                                }
                                onClick={() => {
                                  setCurrentSelectedPackage(thisPackage);
                                }}
                                disabled={!currentCard.card_number}
                              >
                                {thisPackage.total_premium}
                              </PriceButton>
                            );
                          })}
                        </FlexContainer>
                        {currentPackages.length - 1 > groupIndex && <Divider />}
                      </div>
                    );
                  })}
                </div>
                <Divider />
                <Divider />
                <TextTitle fontSize={16}>
                  เงื่อนไขการเติมเงินบัตร M GENERATION
                </TextTitle>
                <SmallDivider />
                <NumberContainer>
                  <NumberItem>-</NumberItem>
                  <NumberText>
                    ไม่สามารถยกเลิกรายการเติมเงินหลังการชำระเงินเรียบร้อย
                  </NumberText>
                </NumberContainer>
                <NumberContainer>
                  <NumberItem>-</NumberItem>
                  <NumberText>
                    หากท่านต้องการตรวจสอบข้อมูลและสถานะบัตร M GEN กรุณาติดต่อ
                    ที่บูธ M GEN เมเจอร์ ซีนีเพล็กซ์ ทุกสาขา หรือ โทร
                    02-515-5555
                  </NumberText>
                </NumberContainer>
              </PaddingContainer>
            )}
          </>
        )}
        <Modal styles={{ outline: 0 }} open={isLoading}>
          <Loading />
        </Modal>
      </Body>
      {!!isPackageLoadingDone === true && !!isCardLoadingDone === true && (
        <Footer>
          {currentSlide + 1 !== allCards.length ? (
            <>
              <SubmitButton onClick={topUp} disabled={!currentSelectedPackage}>
                <TopBarTitle>เติมเงิน</TopBarTitle>
              </SubmitButton>
            </>
          ) : (
            <SubmitButton onClick={addCard}>
              <TopBarTitle>เพิ่มบัตร</TopBarTitle>
            </SubmitButton>
          )}
        </Footer>
      )}
      <Header>
        <TopBar
          back={() => {
            window.location.replace(
              "https://tmn" +
                (process.env.REACT_APP_ENV === "production" ? "" : "-dev") +
                ".app.link/home"
            );
          }}
        >
          <TopBarTitle fontSize={14}>เติมเงินบัตร M GENERATION</TopBarTitle>
        </TopBar>
      </Header>
    </Wrapper>
  );
}

export default Home;
