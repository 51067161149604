import React from "react";
import styled from "styled-components";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import TopBar from "../components/TopBar";
import TopBarTitle from "../components/TopBarTitle";
import ErrorImage from "../assets/images/error_common.png";
import { useHistory } from "react-router-dom";
import { useDataStore } from "../store";

const Container = styled.div`
  margin: 16px;
  margin-top: 66px;
`;

const Button = styled.button`
  font-size: 16px;
  color: white;
  padding: 10px 80px;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: #ff8300;
`;

const ImageContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeadText = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #0e162c;
`;

const SmallText = styled.p`
  text-align: center;
  font-size: 16px;
  color: #0e162c;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterImage = styled.img`
  width: 210px;
  height: 210px;
`;

function ErrorTopup() {
  const [data, setData] = useDataStore();
  const { editCardData = {}, errorCode } = data;
  const history = useHistory();
  const onBack = () => {
    history.push("/home");
    setData({
      editCardData: {},
      setCardToId: editCardData.id,
      errorCode: undefined
    });
  };

  let title = "ไม่สามารถทำรายการได้";
  let desc = "กรุณาทํารายการใหม่ภายหลัง";
  let code = "";

  switch (errorCode) {
    case "1000": {
      title = "บัตรของท่านไม่สามารถทำรายการได้";
      desc = "(#1000)";
      code = "";
      break
    }
    case "0001": {
      title = "หมายเลขบัตรไม่ถูกต้อง";
      desc = "กรุณาตรวจสอบและทํารายการใหม่อีกครั้ง";
      code = "(#0001)";
      break
    }
    case "0004": {
      title = "หมายเลขบัตรไม่ถูกต้อง";
      desc = "กรุณาตรวจสอบและทํารายการใหม่อีกครั้ง";
      code = "(#0004)";
      break
    }
    case "0002": {
      title = "ไม่สามารถทำรายการได้";
      desc = "กรุณาทํารายการใหม่ภายหลัง";
      code = "(#0002)";
      break
    }
    case "9999": {
      title = "ไม่สามารถทำรายการได้";
      desc = "กรุณาทํารายการใหม่ภายหลัง";
      code = "(#9999)";
      break
    }
    case "1001": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจาก ไม่รองรับการเติมเงินเข้าประเภทบัตรนี้\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1001)";
      break
    }
    case "1002": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "โปรดตรวจสอบสถานะบัตรกับผู้ออกบัตร\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1002)";
      break
    }
    case "1003": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจาก ไม่รองรับการเติมเงินเข้าประเภทบัตรนี้\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1003)";
      break
    }
    case "1004": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจาก ไม่รองรับการเติมเงินเข้าประเภทบัตรนี้\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1004)";
      break
    }
    case "1005": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจากบัตรหมดอายุ\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1005)";
      break
    }
    case "1006": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจาก จํานวนเงินไม่ถูกต้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1006)";
      break
    }
    case "1007": {
      title = "เติมเงินเข้าบัตรเรียบร้อย";
      desc = "ขอบคุณที่ใช้บริการ";
      code = "";
      break
    }
    case "9000": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9000)";
      break
    }
    case "9001": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9001)";
      break
    }
    case "9002": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc = "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9002)";
      break
    }
  }

  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          <CenterImage alt="error_image" src={ErrorImage} />
        </ImageContainer>
        <HeadText>{title}</HeadText>
        <SmallText>{desc}</SmallText>
        <SmallText>{code}</SmallText>
        <br />

        <ButtonContainer>
          <Button onClick={onBack}>ย้อนกลับ</Button>
        </ButtonContainer>
      </Container>
      <Header>
        <TopBar
          back={() => {
            history.push("/home");
          }}
        >
          <TopBarTitle>เติมเงินบัตร M Generation</TopBarTitle>
        </TopBar>
      </Header>
    </Wrapper>
  );
}

export default ErrorTopup;
