import React from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";

const FixPosition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 0px;
  background-color: white;
  display: block;
`;

const Container = styled.div`
  background-color: white;
  box-shadow: none;
  height: 250px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee1e9;
  padding-top: 200px;
  margin-top: -200px;
`;

const FlexCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexLeft = styled.div`
  margin-left: 15px;
  width: 30%;
`;

const FlexRight = styled.div`
  text-align: right;
  margin-right: 15px;
  width: 30%;
`;

const LeftIcon = styled(Icon)`
  &&& {
    color: #4b5161;
    font-size: 15px;
  }
`;

const RightIcon = styled(Icon)`
  &&& {
    color: #4b5161;
    font-size: 19px;
    font-weight: bold;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 5px;
  justify-content: space-between;
`;

const DisabledProgress = styled.div`
  height: 5px;
  width: 100%;
  background-color: rgb(205 210 222);
`;

const ActiveProgress = styled.div`
  height: 5px;
  width: 100%;
  background-color: rgb(42 206 170);
`;

const ProgressSplitter = styled.div`
  height: 5px;
  width: "4px;
  background-color: white;
`;

function TopBar({ back, close, children, totalProgress = 0, progress = 0 }) {
  const totalProgressArray = Array.from(Array(totalProgress).keys());
  return (
    <FixPosition>
      <Container>
        {back ? (
          <FlexLeft>
            <IconButton onClick={back}>
              <LeftIcon>arrow_back_ios</LeftIcon>
            </IconButton>
          </FlexLeft>
        ) : (
          <FlexLeft />
        )}
        <FlexCenter>{children}</FlexCenter>
        {close ? (
          <FlexRight>
            <IconButton onClick={close}>
              <RightIcon>close</RightIcon>
            </IconButton>
          </FlexRight>
        ) : (
          <FlexRight />
        )}
      </Container>
      {!!totalProgress && !!progress && (
        <ProgressContainer>
          {totalProgressArray.map(index => {
            const toReturn = [];
            const currentProgress =
              index + 1 <= progress ? <ActiveProgress /> : <DisabledProgress />;
            toReturn.push(currentProgress);
            if (totalProgressArray.length < index + 1)
              toReturn.push(<ProgressSplitter />);
            return toReturn;
          })}
        </ProgressContainer>
      )}
    </FixPosition>
  );
}

TopBar.propTypes = {
  back: PropTypes.func,
  close: PropTypes.func,
  children: PropTypes.any,
  totalProgress: PropTypes.number,
  progress: PropTypes.number,
}

export default TopBar;
