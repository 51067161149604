import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const BodyWrapper = styled.div`
  margin-top: 50px;
  flex-grow: 1;
`;

function Body({ children, gray }) {
  return (
    <BodyWrapper style={gray && { backgroundColor: "#eff0f4" }}>
      {children}
    </BodyWrapper>
  );
}

Body.propTypes = {
  children: PropTypes.any,
  gray: PropTypes.bool
};

export default Body;
