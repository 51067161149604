import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export default Wrapper;
