import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import styled from "styled-components";
import Loading from "../components/Loading";
import API from "../api";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import TopBar from "../components/TopBar";
import TopBarTitle from "../components/TopBarTitle";
import Icon from "@material-ui/core/Icon";

const Container = styled.div`
  margin: 24px;
  padding: 16px;
  margin-top: 66px;
  position: relative;
  background: white;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 0%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px,
    rgb(0 0 0 / 12%) 0px 1px 5px 0px;
`;

const HeadText = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #0e162c;
`;

const SmallText = styled.p`
  text-align: center;
  font-size: 16px;
  color: #0e162c;
`;

const BlueBg = styled.div`
  background-color: #1c90f4;
  width: 100%;
  height: 180px;
  display: block;
  position: absolute;
  top: 0;
`;

const IconContainer = styled.div`
  margin: auto;
  background: rgb(43 206 171);
  margin-top: 16px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FailedIconContainer = styled.div`
  margin: auto;
  background: rgb(201 45 41);
  margin-top: 16px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckIcon = styled(Icon)`
  &&& {
    color: white;
    font-size: 50px;
  }
`;

function Success() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const fetchTransaction = async (isv_payment_ref, counter = 0) => {
    try {
      const getTransactionResponse = await API.get(
        "/get_transaction?isv_payment_ref=" + isv_payment_ref
      );
      const transactionStatus = _.get(
        getTransactionResponse,
        "data.transaction_status"
      );
      const topUpResp = _.get(getTransactionResponse, "data.topup_resp");
      let topUpJson = {};
      try {
        topUpJson = JSON.parse(topUpResp);
      } catch {
        console.log("Failed to parse");
      }
      if (transactionStatus === "success" || transactionStatus === "SUCCESS") {
        setIsLoading(false);
        setIsSuccess(true);
      } else if (transactionStatus === "topup fail") {
        setIsLoading(false);
        setIsSuccess(false);
        if (topUpJson.ReasonCode) {
          setErrorCode(topUpJson.ReasonCode);
        }
      } else {
        if (counter < 15) {
          setTimeout(() => {
            fetchTransaction(isv_payment_ref, counter + 1);
          }, 2000);
        } else {
          setIsLoading(false);
          setIsSuccess(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      console.log("Error fetching transaction");
      if (counter < 15) {
        setTimeout(() => {
          fetchTransaction(isv_payment_ref, counter + 1);
        }, 2000);
      } else {
        setIsLoading(false);
        setIsSuccess(false);
      }
    }
  };
  useEffect(() => {
    const { location } = history;
    if (_.get(location, "search.length", 0) === 0) return history.push("/");
    const splittedData = _.get(location, "search", "")
      .slice(1, _.get(location, "search.length", 0))
      .split("&");
    const params = {};
    splittedData.forEach(data => {
      const splitMore = data.split("=");
      const key = splitMore[0];
      const value = splitMore[1];
      params[key] = value;
    });
    const isv_payment_ref = _.get(params, "isv_payment_ref", "");
    if (isv_payment_ref) {
      fetchTransaction(isv_payment_ref);
    } else {
      setIsSuccess(false);
      setIsLoading(false);
    }
  });
  let title = "ไม่สามารถทำรายการได้";
  let desc = "กรุณาทํารายการใหม่ภายหลัง";
  let code = "";

  switch (errorCode) {
    case "1000": {
      title = "ไม่สามารถทำรายการได้";
      desc = "(#1000)";
      code = "";
      break;
    }
    case "0001": {
      title = "หมายเลขบัตรไม่ถูกต้อง";
      desc = "กรุณาตรวจสอบและทํารายการใหม่อีกครั้ง";
      code = "(#0001)";
      break;
    }
    case "0004": {
      title = "หมายเลขบัตรไม่ถูกต้อง";
      desc = "กรุณาตรวจสอบและทํารายการใหม่อีกครั้ง";
      code = "(#0004)";
      break;
    }
    case "0002": {
      title = "ไม่สามารถทำรายการได้";
      desc = "กรุณาทํารายการใหม่ภายหลัง";
      code = "(#0002)";
      break;
    }
    case "9999": {
      title = "ไม่สามารถทำรายการได้";
      desc = "กรุณาทํารายการใหม่ภายหลัง";
      code = "(#9999)";
      break;
    }
    case "1001": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจาก ไม่รองรับการเติมเงินเข้าประเภทบัตรนี้\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1001)";
      break;
    }
    case "1002": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "โปรดตรวจสอบสถานะบัตรกับผู้ออกบัตร\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1002)";
      break;
    }
    case "1003": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจาก ไม่รองรับการเติมเงินเข้าประเภทบัตรนี้\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1003)";
      break;
    }
    case "1004": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจาก ไม่รองรับการเติมเงินเข้าประเภทบัตรนี้\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1004)";
      break;
    }
    case "1005": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจากบัตรหมดอายุ\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1005)";
      break;
    }
    case "1006": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจาก จํานวนเงินไม่ถูกต้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#1006)";
      break;
    }
    case "1007": {
      title = "เติมเงินเข้าบัตรเรียบร้อย";
      desc = "ขอบคุณที่ใช้บริการ";
      code = "";
      break;
    }
    case "9000": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9000)";
      break;
    }
    case "9001": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9001)";
      break;
    }
    case "9002": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9002)";
      break;
    }
    case "9003": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9003)";
      break;
    }
    case "9004": {
      title = "ไม่สามารถเติมเงินเข้าบัตรได้";
      desc =
        "เนื่องจากระบบเชื่อมต่อกับผู้ออกบัตรขัดข้อง\nระบบจะคืนเงินเข้ากระเป๋าของท่านโดยอัตโนมัติ";
      code = "(#9004)";
      break;
    }
  }
  return (
    <Wrapper>
      <BlueBg />
      {!isLoading && (
        <Container>
          {isSuccess ? (
            <IconContainer>
              <CheckIcon>check</CheckIcon>
            </IconContainer>
          ) : (
            <FailedIconContainer>
              <CheckIcon>close</CheckIcon>
            </FailedIconContainer>
          )}
          {isSuccess ? (
            <HeadText>เติมเงินเข้าบัตร M GENERATION สำเร็จ</HeadText>
          ) : (
            <>
              <HeadText>{title}</HeadText>
              <SmallText>{desc}</SmallText>
              <SmallText>{code}</SmallText>
            </>
          )}
        </Container>
      )}
      <Modal styles={{ outline: 0 }} open={isLoading}>
        <Loading />
      </Modal>
      <Header>
        <TopBar
          close={() => {
            window.location.replace(
              "https://tmn" +
                (process.env.REACT_APP_ENV === "production" ? "" : "-dev") +
                ".app.link/home"
            );
          }}
        >
          <TopBarTitle>รายการสั่งซื้อ</TopBarTitle>
        </TopBar>
      </Header>
    </Wrapper>
  );
}

export default Success;
