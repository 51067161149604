import React from "react";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import Body from "../components/Body";
import TopBar from "../components/TopBar";
import TopBarTitle from "../components/TopBarTitle";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const NumberContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const NumberItem = styled.p`
  font-size: 16px;
  margin-right: 8px;
  margin: 0px;
`;

const NumberText = styled.p`
  font-size: 16px;
  margin: 0px;
`;

const PaddingContent = styled.div`
  padding: 16px;
`;

function Agree() {
  const history = useHistory();

  return (
    <Wrapper>
      <Body>
        <PaddingContent>
          <NumberContainer>
            <NumberItem>-</NumberItem>
            <NumberText>
              ไม่สามารถยกเลิกรายการเติมเงินหลังการชำระเงินเรียบร้อย
            </NumberText>
          </NumberContainer>
          <NumberContainer>
            <NumberItem>-</NumberItem>
            <NumberText>
              หากท่านต้องการตรวจสอบข้อมูลและสถานะบัตร MCash กรุณาติดต่อ MCash
              call center หรือ www.xxxx.com
            </NumberText>
          </NumberContainer>
        </PaddingContent>
      </Body>
      <Header>
        <TopBar
          back={() => {
            history.push("/home");
          }}
        >
          <TopBarTitle>เงื่อนไขการเติมเงินบัตร MCash</TopBarTitle>
        </TopBar>
      </Header>
    </Wrapper>
  );
}

export default Agree;
