import React, { useReducer, useContext, createContext } from "react";
import PropTypes from "prop-types";

const initialState = {
  auth: {
    //token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJEYXRhIjoiTlArRkF3RUJCM0JoZVd4dllXUUIvNFlBQVFNQkEwdGxlUUVLQUFFRlRtOXVZMlVCLzRnQUFRZE5aWE56WVdkbEFRb0FBQUFaLzRjQkFRRUpXekkwWFhWcGJuUTRBZitJQUFFR0FUQUFBUDRCN3YrR0FmK29BUUlEQUhqTk5kcDZFVkRiL1VwWU5US2dGRHIya2VjbmxjQklYeXBDcktOL09QWXZWUUZGaWJ0ZmJEUW9IRE9hdGhJNlZubjRBQUFBYmpCc0Jna3Foa2lHOXcwQkJ3YWdYekJkQWdFQU1GZ0dDU3FHU0liM0RRRUhBVEFlQmdsZ2hrZ0JaUU1FQVM0d0VRUU00N1pGYXZhRFhDSzF3VHBJQWdFUWdDdlAxa1h6WVVVZ3c0MHBsWmJWNEphVkd2S01QNndSZU04NU43RUxKYkJ5eUpwb3VHUnF1R1FhbHA3MUFSZ2ZPLy8vLzlYLzlQK2pSbEwvaEQ3LzBQL0tLUjkvSUN4Ni85RjBhUC9NLzZzYkFmNEJHTjI4b04xODM2UFdVZW5hT05JZXBTRG1sdzNuaEZUTW5JM2VPbU9BVFBDalM1R2Q2TFdrZ1B2dEEzbHVtVnNFQ2pCUjZDVHQwbTluMGY5WHRJV0dWQ01TNHFyeFNMS1NRNWxOVGlUNFRjamUveERNOWk1RHYzYzJvR0habUlCUUlHVFpXTWU1eFEwOG5rbWhjSENqQ1lOUUFLYUxFYlBubFYxeVo2c2lrWnZVK2ZKUG95TEdmaDc2NDA4TUYwZ0sxTGFzR01EeWt1SGp1WkNlUlkzOHpnUkZ3cFpJV2RlaDVid3pGVW9WTGdhQ25mK3N5eE1vVWZ4NGNITk8wVmQrRXlNdkkxbGdxYXRLWUMrR3ROcllBWnNFVG5CdTdRZ0hmT3ZXVmlqVzhuZFRXTEZnVDIrNFhicXA0anJzcmpldDhKTzZnNXpHeDJBSGVkbU5WbTlaTlp5ZVpLR1Q3L2RhUjJ0aW80eUZSWm1BZmpoazFOZ1IwTXNBIiwiZXhwIjoxNjExNzUyOTA3fQ.VBFCndg9Q4OQv3xMO7m1wMyMfcNtGjrZ6IOqaMvvkAQ"
  },
  data: {}
};
const reducer = (state, action) => {
  switch (action.type) {
    case "auth": {
      return {
        ...state,
        auth: {
          ...state.auth,
          ...action.data
        }
      };
    }
    case "data": {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };
    }
    default:
      throw new Error("Unexpected action");
  }
};

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const storeValue = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={storeValue}>{children}</StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.any
};

export const useStore = () => {
  const storeValue = useContext(StoreContext);
  return storeValue;
};

export const useAuthStore = () => {
  const storeValue = useContext(StoreContext);
  const [state, dispatch] = storeValue;
  return [
    state.auth,
    data => {
      dispatch({ type: "auth", data });
    }
  ];
};

export const useDataStore = () => {
  const storeValue = useContext(StoreContext);
  const [state, dispatch] = storeValue;
  return [
    state.data,
    data => {
      dispatch({ type: "data", data });
    }
  ];
};
