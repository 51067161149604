import React from "react";
import styled from "styled-components";
import CircularProgress from '@material-ui/core/CircularProgress'

const ColorCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    & .MuiCircularProgress-svg {
      color: #ff8300!important;
    }
    & .MuiCircularProgress-circle {
      color: #ff8300!important;
    }
  }
`;

const Loading = () => {
  return (
    <ColorCircularProgress
      size={40}
      thickness={5}
    />
  );
};

export default Loading;
