import React from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import routes from "./routes";
import { StoreProvider } from "./store";

const Root = styled.div`
  position: fixed;
  z-ndex: 1;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  overflow: auto;
`;

function App() {
  return (
    <Root>
      <StoreProvider>
        <CssBaseline />
        <Router>
          <Switch>
            {routes.map((prop, key) => {
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route
                  key={key}
                  path={prop.path}
                  component={prop.component}
                  exact
                />
              );
            })}
          </Switch>
        </Router>
      </StoreProvider>
    </Root>
  );
}

export default App;
