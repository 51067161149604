import Home from "../views/Home";
import Callback from "../views/Callback";
import AddCard from "../views/AddCard";
import ErrorTopup from "../views/ErrorTopup";
import ErrorMa from "../views/ErrorMa";
import Agree from "../views/Agree";
import Success from "../views/Success";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback
  },
  {
    path: "/add-card",
    name: "AddCard",
    component: AddCard
  },
  {
    path: "/error-topup",
    name: "ErrorTopup",
    component: ErrorTopup
  },
  {
    path: "/error-ma",
    name: "ErrorMa",
    component: ErrorMa
  },
  {
    path: "/agree",
    name: "Agree",
    component: Agree
  },
  {
    path: "/success",
    name: "Success",
    component: Success
  },
  { redirect: true, path: "/", pathTo: "/home", name: "Home" }
];

export default routes;
