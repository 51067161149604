import React, { useEffect } from "react";
import { useHistory } from "react-router";
import _ from "lodash";
import { useAuthStore } from "../store";
import API from "../api";

function Callback() {
  const [, setAuth] = useAuthStore();
  const history = useHistory();

  useEffect(async () => {
    const location = _.get(window, "location", {});
    const search = _.get(location, "search", "");
    if (search.length === 0) return true;
    const splittedData = search.slice(1, search.length).split("&");
    const params = {};
    splittedData.forEach(data => {
      const splitMore = data.split("=");
      const key = _.get(splitMore, 0, "");
      const value = _.get(splitMore, 1, "");
      params[key] = value;
    });
    const token = _.get(params, "token", "");
    const initialData = await API.getWithToken(token, "/initial");
    const data = _.get(initialData, "data", {});
    const currentUserData = _.get(data, "current_user.data", {});
    const tokenJWT = _.get(data, "tokenJWT", {});
    const systems = _.get(data, "systems", []);
    const currentSystem = systems.find(system => system.name === "mcard");
    setAuth({
      ...currentUserData,
      token: tokenJWT
    });
    if (currentSystem && !!currentSystem.enable === false) {
      history.push("/error-ma");
    } else {
      history.push("/home");
    }
  }, []);

  return <div />;
}

export default Callback;
