import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ButtonWrapper = styled.div`
  display: block;
  border-radius: 30px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  font-family: thonburiregular;
  font-style: normal;
  font-size: 18px;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: opacity 0.05s ease-in-out;
  -moz-transition: opacity 0.05s ease-in-out;
  -ms-transition: opacity 0.05s ease-in-out;
  -o-transition: opacity 0.05s ease-in-out;
  transition: opacity 0.05s ease-in-out;
  &:active {
    opacity: 0.7;
  }
`;

const ClearButtonWrapper = styled(ButtonWrapper)`
  border: 1px solid #ff8300;
  p {
    color: #ff8300 !important;
  }
`;

const DisabledClearButtonWrapper = styled(ClearButtonWrapper)`
  opacity: 0.5;
`;

const ClearMarginButtonWrapper = styled(ClearButtonWrapper)`
  margin: 15px;
`;

const DisabledClearMarginButtonWrapper = styled(ClearMarginButtonWrapper)`
  opacity: 0.5;
`;

const ColoredButtonWrapper = styled(ButtonWrapper)`
  p {
    color: white !important;
  }
  background-color: #ff8300;
`;

const DisabledColoredButtonWrapper = styled(ColoredButtonWrapper)`
  background-color: rgb(216 216 216);
`;

const ColoredMarginButtonWrapper = styled(ColoredButtonWrapper)`
  margin: 15px;
`;

const DisabledColoredMarginButtonWrapper = styled(ColoredMarginButtonWrapper)`
  background-color: rgb(216 216 216);
`;

function SubmitButton({ children, clear, disabled, margin = true, onClick }) {
  let ThisElement = ButtonWrapper;
  if (clear) {
    if (margin) {
      ThisElement = disabled
        ? DisabledClearMarginButtonWrapper
        : ClearMarginButtonWrapper;
    } else {
      ThisElement = disabled ? DisabledClearButtonWrapper : ClearButtonWrapper;
    }
  } else {
    if (margin) {
      ThisElement = disabled
        ? DisabledColoredMarginButtonWrapper
        : ColoredMarginButtonWrapper;
    } else {
      ThisElement = disabled
        ? DisabledColoredButtonWrapper
        : ColoredButtonWrapper;
    }
  }
  return <ThisElement onClick={!disabled && onClick}>{children}</ThisElement>;
}

SubmitButton.propTypes = {
  children: PropTypes.any,
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SubmitButton;
