import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

const TopBarTitle = styled(Typography)`
  &&& {
    color: #333333;
    font-size: ${props => props.fontSize ? props.fontSize + "px" : "18px"};
    font-weight: bold;
  }
`

export default TopBarTitle;
