import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const FooterWrapper = styled.div`
  flex-shrink: 0;
`;

function Footer({ children, gray }) {
  return (
    <FooterWrapper style={gray && { backgroundColor: "#eff0f4" }}>
      {children}
    </FooterWrapper>
  );
}

Footer.propTypes = {
  children: PropTypes.any,
  gray: PropTypes.bool
};

export default Footer;
